import axios from 'axios';
import { Base64 } from 'js-base64';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BassURl } from '../../Api/Api';
import { MobileUserLoginAction, UserLoginAction } from '../../Redux/Action/UserLoginAction';
import './Tlogin.css'

function Tlogin() {
    const dispatch = useDispatch();

    const loginreducer = useSelector(state => state.UserLoginReducer);
    const { loading, error } = loginreducer
    let initialValues = {
        phonenumber: "", userid: "",
        password: "", type: ""
    }




    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const handleChange = (e) => {

        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        console.log(formValues);
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            console.log(formValues, "USEEFFECT");

            // let alldata={
            //   formValues, dropdata
            // }
            // console.log(alldata)
            axios.post(`http://project.digitalnawab.com/kidsschoolnew/api/login`, formValues).then((res) => {
                console.log(res)
            })
                // catch error if their is any error
                .catch((err) => {
                    console.log(err)
                })


            console.table(formValues);
        }
    }, [formErrors]);


    const params = JSON.parse(localStorage.getItem('params'));
    let datas = Base64.decode(params?.url);

    const userData = {
        mobile_number: formValues.phonenumber,
        user_id: formValues.userid,
        password: formValues.password,
        type: formValues.type,
        branch_id: datas
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        axios.post(`${BassURl}api/auth/login`, userData).then((res) => {
            console.log(res.data.data)
            if (res.data.code === 200) {
                localStorage.setItem('currentUser', JSON.stringify(res?.data?.data))
                window.location.href = `/branch/${params.branch_url}/${params.url}/mobile-select-class`


            }
            else {
                alert(res.data.msg)
            }

            // if (res.data.data.type === 'student') {
            //     console.log(res.data.data)
            //  
            // }

        })
            // catch error if their is any error
            .catch((err) => {
                console.log(err)
            })
    }

    // Class Api Call Here

    const [classes, setClass] = useState([]);
    // schooll api call here
    const [school, setSchool] = useState([]);
    //  function call class api  
    useEffect(() => {


        axios.get(`${BassURl}api/all-class`).then((res) => {
            setClass(res?.data?.data)

            // {res.data.code == 200 ? setClass(res?.data?.data): ('erro')}
            // console.log(res?.data?.data);
        }).catch((err) => {
            console.log(err)

        })
    }, [])

    // scholl API FUNCTION

    useEffect(() => {


        axios.get(`${BassURl}api/all-school`).then((res) => {
            setSchool(res?.data?.data)

            // {res.data.code == 200 ? setClass(res?.data?.data): ('erro')}
            console.log(res?.data?.data);
        }).catch((err) => {
            console.log(err)

        })
    }, []);
    const branchdata = JSON.parse(localStorage.getItem('branchData'));
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    function logout() {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('BookId');
        localStorage.removeItem('subjectId');
        localStorage.removeItem('class_id');
        setTimeout(window.location.href = `/branch/${params.branch_url}/${params.url}`, 1)


    }

    return (<>
        <div id="page">
            <div id="footer-bar" className="footer-bar-1">
                {currentUser ? (<> <a href={`/branch/${params.branch_url}/${params.url}/mobile-select-class`} className="active-nav"><i class="fa fa-home"></i><span>Home</span></a></>) : (<>  <a href={`/branch/${params.branch_url}/${params.url}`} className="active-nav"><i class="fa fa-home"></i><span>Home</span></a></>)}


                {
                    currentUser ? (<> <a onClick={logout}><i className="fa fa-sign-out"></i><span>Log Out</span></a></>) : (<> <a href={`/branch/${params.branch_url}/${params.url}/all-login`}><i className="fa fa-user"></i><span>Login</span></a></>)


                }

                <a href="/"><i className="fa fa-cog"></i><span>Menu</span></a>
            </div>
            <div className="login-form ">

                <div className="container">
                    <div className="main">
                        <div className="content">
                            <div className="callout_title">
                                <div className="bees bees-end" style={{ marginLeft: "60px" }}>
                                    <span />
                                </div>
                            </div>

                            <h2 style={{ marginTop: "-40px" }}>
                                <img
                                    className="logo"
                                    src={`${BassURl}${branchdata.logo}`}
                                    alt=""
                                    style={{ width: "50%" }}
                                /></h2>


                            {error && (<div class="alert alert-danger" role="alert">
                                something went wrong
                            </div>)}

                            <form
                                onSubmit={handleSubmit}
                                style={{ padding: "12px" }}

                            >

                                <input className="form-control" type="tel" value={formValues.phonenumber} onChange={handleChange} name="phonenumber" placeholder="Phone Number " required />

                                <input className="form-control" type="text" value={formValues.userid} onChange={handleChange} name="userid" placeholder="User Id " required />

                                <input className="form-control" type="password" value={formValues.password} onChange={handleChange} id="password" name="password" placeholder="password" required />
                                <div className='row mt-3 mb-3'>
                                    <div className='col'>
                                        <div>
                                            <input type="radio" id="test1" name="type" value='Teacher' onChange={handleChange} />
                                            <label htmlFor="test1">Teacher</label>
                                        </div>
                                    </div>
                                    <div className='col'>

                                        <div>
                                            <div>
                                                <input type="radio" id="test2" name="type" value='Student' onChange={handleChange} />
                                                <label htmlFor="test2">Student</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <button name="submit" class="btn btn-secondary" onClick={handleSubmit}>Login</button> */}
                                <a type="button text-center" name='submit' class="btn btn-secondary" onClick={handleSubmit} style={{ width: "100%" }}>
                                    Login
                                </a>
                            </form>


                        </div>

                    </div>
                </div>
            </div>

        </div>


    </>)





}

export default Tlogin
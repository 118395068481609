import React from 'react'
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { BassURl } from "../Api/Api";
import SelectNavbar from "../Navbar/SelectNavbar";
import Footer from "./Footer";
import VideoPlayer from "./VideoPlayer";
import ReactPlayer from "react-player";
import ebook from "./ebook.gif";
import study from './study.gif'
import { useParams } from "react-router-dom";
import Filtered from "./Filtered";
import acve from './acev.png'
import tree from './tree.webp'

function ChapterwiseDetails() {
    const bookId = JSON.parse(localStorage.getItem("BookId"));
    const param = useParams();
    const filtered = JSON.parse(localStorage.getItem('filteredItem'));
    const subjectData = JSON.parse(window.localStorage.getItem('SubjectData'));
    const params = JSON.parse(localStorage.getItem('params'));
    console.log(subjectData.title);
    const [chapterwise, setchapterwise] = useState([]);
    useEffect(() => {
        axios
            .get(`${BassURl}/api/books-chapter?book_id=${bookId}`)
            .then((res) => {
                setchapterwise(res?.data?.data);
                // {res.data.code == 200 ? setClass(res?.data?.data): ('erro')}
                // console.log(res?.data?.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    const [mediaType, setmediaType] = useState("");
    // alert(mediaType);

    function filter(e) {
        e.preventDefault();
        // alert(mediaType);
    }

    //   const [filter, setfilter] = useState()

    const filtere = chapterwise?.filter((data) => {

        return data.media_type === mediaType;

    });
    // alert(mediaType)
    console.log(filtere, 'hello');

    return (
        <>

            <SelectNavbar />
            <section className="page-header section-notch">
                <div className="overlay p-5">
                    <div className="container">
                        <h3> </h3>
                        <ul>
                            {subjectData?.slice(0, 1).map((data) => {
                                return (<> <h2 className='blink_me' style={{ color: "white", fontFamily: "revert" }}>{data?.title}</h2></>)
                            })
                            }
                        </ul>
                    </div>
                    {/* container */}`
                </div>
                {/* overlay */}
            </section>

            <section className="classes pt-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className='col-lg-2 position-relative top-0'>


                        </div>

                        <div className='col-lg-7'>
                            <div className="row">
                                {mediaType === "All Chapters Video" ? (
                                    <>
                                        {
                                            filtere?.map((data) => {

                                                return (
                                                    <>
                                                        <div className="col-lg-6 col-sm-6 col-xs-12">
                                                            <div className="class-item">
                                                                <div className="image">
                                                                    {data?.media_type == "All Chapters Video" ? (
                                                                        <>

                                                                            <VideoPlayer
                                                                                width={480}
                                                                                height={345}
                                                                                data={data}
                                                                            />
                                                                        </>
                                                                    ) : null}
                                                                </div>

                                                                {/* */}
                                                                <div className="content">
                                                                    <h4>
                                                                        <a >
                                                                            {data?.course_title}
                                                                        </a>
                                                                    </h4>

                                                                    <p>
                                                                        {data?.short_description}
                                                                    </p>
                                                                </div>
                                                                <div className="address">
                                                                    <p>
                                                                        <span></span>{" "}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            {/* class item */}
                                                        </div>
                                                    </>
                                                );
                                            })


                                        }
                                    </>
                                ) : mediaType === "Full Video book" ? (<>
                                    {
                                        filtere?.map((data) => {

                                            return (
                                                <>
                                                    {/* {console.log(data, "map function")} */}
                                                    <div className="col-lg-4 col-sm-4 col-xs-12">
                                                        <div className="class-item">
                                                            <div className="image">
                                                                {data?.media_type == "Full Video book" ? (
                                                                    <>

                                                                        <VideoPlayer
                                                                            width={480}
                                                                            height={345}
                                                                            data={data}
                                                                        />
                                                                    </>
                                                                ) : null}
                                                            </div>

                                                            {/* */}
                                                            <div className="content">
                                                                <h4>
                                                                    <a >
                                                                        {data?.course_title}
                                                                    </a>
                                                                </h4>

                                                                <p>
                                                                    {data?.short_description}
                                                                </p>
                                                            </div>
                                                            <div className="address">
                                                                <p>
                                                                    <span></span>{" "}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {/* class item */}
                                                    </div>
                                                </>
                                            );
                                        })
                                    }
                                </>) : mediaType === "Full Video Book Link" ? (<>

                                    {
                                        filtere?.map((data) => {

                                            return (
                                                <>
                                                    {/* {console.log(data, "map function")} */}
                                                    <div className="col-lg-4 col-sm-4 col-xs-12">
                                                        <div className="class-item">
                                                            <div className="image">
                                                                {data?.media_type == "Full Video Book Link" ? (
                                                                    <>

                                                                        <ReactPlayer
                                                                            width={430}
                                                                            height={300}
                                                                            url={`${BassURl}${data?.media_url}`}
                                                                        />
                                                                    </>
                                                                ) : null}
                                                            </div>

                                                            {/* */}
                                                            <div className="content">
                                                                <h4>
                                                                    <a>
                                                                        {data?.course_title}
                                                                    </a>
                                                                </h4>

                                                                <p>
                                                                    {data?.short_description}
                                                                </p>
                                                            </div>
                                                            <div className="address">
                                                                <p>
                                                                    <span></span>{" "}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {/* class item */}
                                                    </div>
                                                </>
                                            );
                                        })
                                    }

                                </>) : mediaType === "All Chapters Video Link" ? (<>

                                    {
                                        filtere?.map((data) => {

                                            return (
                                                <>
                                                    {/* {console.log(data, "map function")} */}
                                                    <div className="col-lg-4 col-sm-4 col-xs-12">
                                                        <div className="class-item">
                                                            <div className="image">
                                                                {data?.media_type == "All Chapters Video Link" ? (
                                                                    <>

                                                                        <ReactPlayer
                                                                            width={430}
                                                                            height={300}
                                                                            url={`${BassURl}${data?.media_url}`}
                                                                        />
                                                                    </>
                                                                ) : null}
                                                            </div>

                                                            {/* */}
                                                            <div className="content">
                                                                <h4>
                                                                    <a >
                                                                        {data?.course_title}
                                                                    </a>
                                                                </h4>

                                                                <p>
                                                                    {data?.short_description}
                                                                </p>
                                                            </div>
                                                            <div className="address">
                                                                <p>
                                                                    <span></span>{" "}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {/* class item */}
                                                    </div>
                                                </>
                                            );
                                        })
                                    }

                                </>) : mediaType === "PDF Flip Book Link" ? (<>

                                    {
                                        filtere?.map((data) => {

                                            return (
                                                <>
                                                    {/* {console.log(data, "map function")} */}
                                                    <div className="col-lg-4 col-sm-4 col-xs-12">
                                                        <div className="class-item">
                                                            <div className="image">
                                                                {data?.media_type == "PDF Flip Book Link" ? (
                                                                    <>

                                                                        <img
                                                                            src={ebook}
                                                                            alt=""
                                                                            srcset=""
                                                                            style={{ height: "256px" }}
                                                                        />


                                                                    </>
                                                                ) : null}
                                                            </div>
                                                            <ul className="schedule">
                                                                <li style={{ width: "100%" }}>
                                                                    <span>
                                                                        <a
                                                                            href={`${data?.media_url}`}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            style={{ color: "white" }}
                                                                        >
                                                                            {data?.course_title}
                                                                        </a>
                                                                    </span>
                                                                </li>
                                                            </ul>

                                                            {/* */}
                                                            <div className="content">
                                                                <h4>
                                                                    <a >
                                                                        {data?.course_title}
                                                                    </a>
                                                                </h4>

                                                                <p>
                                                                    {data?.short_description}
                                                                </p>
                                                            </div>
                                                            <div className="address">
                                                                <p>
                                                                    <span></span>{" "}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {/* class item */}
                                                    </div>
                                                </>
                                            );
                                        })
                                    }



                                </>) : mediaType === "Full Video book" ? (<>


                                    {
                                        filtere?.map((data) => {

                                            return (
                                                <>
                                                    {/* {console.log(data, "map function")} */}
                                                    <div className="col-lg-4 col-sm-4 col-xs-12">
                                                        <div className="class-item">
                                                            <div className="image">
                                                                {data?.media_type == "Full Video book " ? (
                                                                    <>

                                                                        <img
                                                                            src={ebook}
                                                                            alt=""
                                                                            srcset=""
                                                                            style={{ height: "256px" }}
                                                                        />


                                                                    </>
                                                                ) : null}
                                                            </div>
                                                            <ul className="schedule">
                                                                <li style={{ width: "100%" }}>
                                                                    <span>
                                                                        <a
                                                                            href={`${BassURl}${data?.media_url}`}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            style={{ color: "white" }}
                                                                        >
                                                                            {data?.course_title}
                                                                        </a>
                                                                    </span>
                                                                </li>
                                                            </ul>

                                                            {/* */}
                                                            <div className="content">
                                                                <h4>
                                                                    <a >
                                                                        {data?.course_title}
                                                                    </a>
                                                                </h4>

                                                                <p>
                                                                    {data?.short_description}
                                                                </p>
                                                            </div>
                                                            <div className="address">
                                                                <p>
                                                                    <span></span>{" "}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {/* class item */}
                                                    </div>
                                                </>
                                            );
                                        })
                                    }
                                </>) : mediaType === "Download" ? (<>
                                    {
                                        filtere?.map((data) => {

                                            return (
                                                <>
                                                    {/* {console.log(data, "map function")} */}
                                                    <div className="col-lg-4 col-sm-4 col-xs-12">
                                                        <div className="class-item">
                                                            <div className="image">
                                                                {data?.media_type == "Download" ? (
                                                                    <>

                                                                        <img
                                                                            src={ebook}
                                                                            alt=""
                                                                            srcset=""
                                                                            style={{ height: "256px" }}
                                                                        />


                                                                    </>
                                                                ) : null}
                                                            </div>
                                                            <ul className="schedule">
                                                                <li style={{ width: "100%" }}>
                                                                    <span>
                                                                        <a
                                                                            // download={}
                                                                            href={`${BassURl}${data?.upload}`}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            style={{ color: "white" }}
                                                                        >
                                                                            {data?.course_title}
                                                                        </a>
                                                                    </span>
                                                                </li>
                                                            </ul>

                                                            {/* */}
                                                            <div className="content">
                                                                <h4>
                                                                    <a >
                                                                        {data?.course_title}
                                                                    </a>
                                                                </h4>

                                                                <p>
                                                                    {data?.short_description}
                                                                </p>
                                                            </div>
                                                            <div className="address">
                                                                <p>
                                                                    <span></span>{" "}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {/* class item */}
                                                    </div>
                                                </>
                                            );
                                        })
                                    }
                                </>) : mediaType === "Teacher Resources Manual link" ? (<>

                                    {
                                        filtere?.map((data) => {

                                            return (
                                                <>
                                                    {/* {console.log(data, "map function")} */}
                                                    <div className="col-lg-4 col-sm-4 col-xs-12">
                                                        <div className="class-item">
                                                            <div className="image">
                                                                {data?.media_type === "Teacher Resources Manual link" ? (
                                                                    <>

                                                                        <img
                                                                            src={ebook}
                                                                            alt=""
                                                                            srcset=""
                                                                            style={{ height: "256px" }}
                                                                        />


                                                                    </>
                                                                ) : null}
                                                            </div>
                                                            <ul className="schedule">
                                                                <li style={{ width: "100%" }}>
                                                                    <span>
                                                                        {data?.media_type == "Teacher Resources Manual link" ? (<>

                                                                            <a
                                                                                // download={}
                                                                                href={`${data?.media_url}`}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                style={{ color: "white" }}
                                                                            >
                                                                                {data?.course_title}
                                                                            </a>





                                                                        </>)



                                                                            : null}

                                                                    </span>
                                                                </li>
                                                            </ul>

                                                            {/* */}
                                                            <div className="content">
                                                                <h4>
                                                                    <a >
                                                                        {data?.course_title}
                                                                    </a>
                                                                </h4>

                                                                <p>
                                                                    {data?.short_description}
                                                                </p>
                                                            </div>
                                                            <div className="address">
                                                                <p>
                                                                    <span></span>{" "}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {/* class item */}
                                                    </div>
                                                </>
                                            );
                                        })
                                    }


                                </>) : mediaType === "Test Generator" ? (<>


                                    {
                                        filtere?.map((data) => {

                                            return (
                                                <>
                                                    {/* {console.log(data, "map function")} */}
                                                    <div className="col-lg-4 col-sm-4 col-xs-12">
                                                        <div className="class-item">
                                                            <div className="image">
                                                                {data?.media_type == "Test Generator" ? (
                                                                    <>

                                                                        <img
                                                                            src={ebook}
                                                                            alt=""
                                                                            srcset=""
                                                                            style={{ height: "256px" }}
                                                                        />


                                                                    </>
                                                                ) : null}
                                                            </div>
                                                            <ul className="schedule">
                                                                <li style={{ width: "100%" }}>
                                                                    <span>
                                                                        <a
                                                                            // download={}
                                                                            href={`${data?.media_url}`}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            style={{ color: "white" }}
                                                                        >
                                                                            {data?.course_title}
                                                                        </a>
                                                                    </span>
                                                                </li>
                                                            </ul>

                                                            {/* */}
                                                            <div className="content">
                                                                <h4>
                                                                    <a >
                                                                        {data?.course_title}
                                                                    </a>
                                                                </h4>

                                                                <p>
                                                                    {data?.short_description}
                                                                </p>
                                                            </div>
                                                            <div className="address">
                                                                <p>
                                                                    <span></span>{" "}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {/* class item */}
                                                    </div>
                                                </>
                                            );
                                        })
                                    }


                                </>) : (
                                    filtered?.map((data) => {
                                        return (<><div className="col-lg-4 col-sm-4 col-xs-12">
                                            <div className="class-item">
                                                <div className="image">
                                                    {data?.media_type == "All Chapters Video" ? (
                                                        <>

                                                            <VideoPlayer
                                                                width={480}
                                                                height={345}
                                                                data={data}
                                                            />
                                                        </>
                                                    ) : null}
                                                </div>

                                                {/* */}
                                                {data?.media_type == "All Chapters Video" ? (
                                                    <>

                                                        <div className="content">
                                                            <h4>
                                                                <a >
                                                                    {data?.course_title}
                                                                </a>
                                                            </h4>

                                                            <p>
                                                                {data?.short_description}
                                                            </p>
                                                        </div>
                                                        <div className="address">
                                                            <p>
                                                                <span></span>{" "}
                                                            </p>
                                                        </div>
                                                    </>
                                                ) : null}


                                            </div>
                                            {/* class item */}
                                        </div></>)
                                    })



                                )}



                            </div>


                        </div>
                        <div className='col-lg-12 w-100 bottom-0 bg-white multiClassDiv' style={{
                            filter: " drop-shadow(2px 4px 6px black)",


                            borderTop: " 4px solid orange",
                            zIndex: "1000",
                            // backgroundImage: `url(${student})`,
                            backgroundRepeat: "no-repeat",

                            position: "fixed",
                        }}>
                            <div className="row">




                                {/* <div className='col-lg-7'> <div className="row">
                                    <div className="col-lg-4 ">
                                        <button style={{ backgroundColor: "yellow", color: "black", borderRadius: "5px ", width: "500px ", fontSize: "18px" }} onClick={(e) => { setmediaType("All Chapters Video"); localStorage.removeItem('filteredItem') }}>
                                            <a style={{ color: "black" }}> All Chapters Video</a>

                                        </button>



                                    </div>
                                    <div className="col-lg-4 ">
                                        <button style={{ backgroundColor: "#e84b3a", color: "white", borderRadius: "5px", width: "500px", fontSize: "18px" }} onClick={(e) => setmediaType("Full Video book")}>Full Video</button>
                                    </div>
                                    <div className="col-lg-4 ">
                                        <button style={{ backgroundColor: "#88c87b", color: "white", width: "500px", fontSize: "18px", borderRadius: "5px" }} onClick={(e) => setmediaType("PDF Flip Book Link")}>
                                            <a href="http://wp-test-closetoutline-com.nt1-p4stl.ezhostingserver.com/class-1-aao-vyakaran-seekhe-trm/" style={{ color: "white" }}>  Flip Book Link</a>
                                        </button>
                                    </div>
                                </div></div>
                                <div className='col-lg-5'><div className="row">
                                    <div className="col-lg-6  ">
                                        <button className="" style={{ backgroundColor: "rgb(12 13 252)", color: "white", width: "500px ", fontSize: "18px", borderRadius: "5px" }} onClick={(e) => setmediaType("TRM")}>
                                            <a style={{ color: "white" }}>Teacher Resource Manual</a>


                                        </button>
                                    </div>

                                    <div className="col-lg-6 ">
                                        <button style={{ backgroundColor: "rgb(12 13 252)", color: "white", width: "500px ", fontSize: "18px", borderRadius: "5px" }} onClick={(e) => setmediaType("Test Generator")}>
                                            Test Generator
                                        </button>
                                    </div>



                                </div></div> */}
                                <div className="row align-items-center">
                                    <div className="col-9 ">
                                        <div className="row text-center ">

                                            <div className="col ">  <button className='chaptervideos' style={{ backgroundColor: "yellow", color: "black", borderRadius: "5px ", fontSize: "18px" }} onClick={(e) => { setmediaType("All Chapters Video"); localStorage.removeItem('filteredItem') }}>
                                                <a style={{ color: "black" }}> All Chapters Video</a>

                                            </button></div>
                                            <div className="col">  <button className='chaptervideos' style={{ backgroundColor: "#88c87b", color: "white", fontSize: "18px", borderRadius: "5px" }} onClick={(e) => setmediaType("PDF Flip Book Link")}>
                                                <a style={{ color: "white" }}>  Flip Book </a>
                                            </button></div>
                                            <div className="col"><button className='chaptervideos' style={{ backgroundColor: "#e84b3a", color: "white", borderRadius: "5px", fontSize: "18px" }} onClick={(e) => setmediaType("Full Video book")}>Full Video</button></div>
                                            <div className="col"><button className='chaptervideos' style={{ backgroundColor: "rgb(12 13 252)", color: "white", fontSize: "17px", borderRadius: "5px" }} onClick={(e) => setmediaType("Teacher Resources Manual link")}>
                                                <a style={{ color: "white" }}>Teacher Resource Manual</a>


                                            </button></div>
                                            <div className="col"><button className='chaptervideos' style={{ backgroundColor: "rgb(12 13 252)", color: "white", fontSize: "18px", borderRadius: "5px" }} onClick={(e) => setmediaType("Test Generator")}>
                                                Test Generator
                                            </button></div>


                                        </div>

                                    </div>
                                    <div className="col-3">
                                        <div className="row">

                                            <div className='col-lg-6'>

                                                <img src={acve} alt="" srcset="" />
                                            </div>
                                            <div className='col-lg-6'>

                                                <img src={tree} style={{ width: "60%" }} alt="" />
                                            </div>

                                        </div>
                                    </div>

                                </div>

                                {/* <div className="col-lg-4 ">
  <button style={{ backgroundColor: "rgb(237 41 20)", color: "white", width: "500px", fontSize: "18px" , borderRadius:"5px" }} onClick={(e) => setmediaType("Full Video Book Link")}>Video Link</button>
</div> */}
                                {/* <div className="col-lg-4 mt-3">
  <button style={{ backgroundColor: "#008c99", color: "white", fontSize: "18px ", width: "500px",borderRadius:"5px" }} onClick={(e) => setmediaType("All Chapters Video Link")}>Chapter Videos Link</button>
</div> */}
                                {/* <div className="col-lg-4 mt-3">
  <button className="" style={{ backgroundColor: "rgb(132 186 48)", color: "white", width: "500px ", fontSize: "18px" , borderRadius:"5px" }} onClick={(e) => setmediaType("PDF Flip Book")}>Flip Book </button>

</div> */}



                                {/* <div className="col-lg-12 text-center mt-3">
  <button className="" style={{ backgroundColor: "rgb(94 28 209)", color: "white", width: "700px ", fontSize: "18px", borderRadius:"5px" }} onClick={(e) => setmediaType("Download")}>
    Download
  </button>
</div> */}

                            </div>
                        </div>

                    </div>

                </div>

                <div className="container-fluid mt-4">



                    {/* row */}
                </div>
                {/* container */}
            </section>




        </>
    )
}

export default ChapterwiseDetails
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BassURl } from "../../Api/Api";
import "./mobilesection.css";
import acve from "../acev.png";
import tree from "../tree.webp";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Base64 } from "js-base64";
import { useParams } from "react-router-dom";
import Banner from "./Banner";

function MobileSelectclass() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const param = JSON.parse(localStorage.getItem("currentUser"));
  const params = JSON.parse(localStorage.getItem("params"));
  const [classes, setClasses] = React.useState([]);
  useEffect(() => {
    axios
      .get(`${BassURl}api/all-class?school_id=${param?.school_id}`)
      .then((res) => {
        setClasses(res.data.data);
      })
      .catch((err) => {
        alert(err);
      });
  }, []);

  const branchData = JSON.parse(localStorage.getItem("branchData"));
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const branchLogo = JSON.parse(localStorage.getItem("branchlogo"));
  const class_id = localStorage.setItem("class_id", JSON.stringify(classes));

  function logout() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("BookId");
    localStorage.removeItem("subjectId");
    localStorage.removeItem("class_id");
    setTimeout(
      (window.location.href = `/branch/${params.branch_url}/${params.url}`),
      1
    );
  }
  console.log(branchLogo);

  const paramsData = useParams();
  let datas = Base64.decode(paramsData?.url);
  console.log(datas);

  const [slider, setSlider] = useState("");

  useEffect(() => {
    axios.get(`${BassURl}/api/branch-slider?branch_id=${datas}`).then((res) => {
      setSlider(res.data.data);
    });
  }, []);

  console.log(slider);

  return (
    <>
      <div id="page">
        <div id="footer-bar" className="footer-bar-1">
          {currentUser ? (
            <>
              {" "}
              <a
                href={`/branch/${params.branch_url}/${params.url}/mobile-select-class`}
                className="active-nav"
              >
                <i class="fa fa-home"></i>
                <span>Home</span>
              </a>
            </>
          ) : (
            <>
              {" "}
              <a
                href={`/branch/${params.branch_url}/${params.url}`}
                className="active-nav"
              >
                <i class="fa fa-home"></i>
                <span>Home</span>
              </a>
            </>
          )}

          {currentUser ? (
            <>
              {" "}
              <a onClick={logout}>
                <i className="fa fa-sign-out"></i>
                <span>Log Out</span>
              </a>
            </>
          ) : (
            <>
              {" "}
              <a href={`/branch/${params.branch_url}/${params.url}/all-login`}>
                <i className="fa fa-user"></i>
                <span>Login</span>
              </a>
            </>
          )}

          <a href="/">
            <i className="fa fa-cog"></i>
            <span>Menu</span>
          </a>
        </div>

        <div className="page-content header-clear-small">
          <div className="logo-row-mobile">
            <img
              src={`${BassURl}${branchData.logo}`}
              alt="logo"
              className="logo-mobile"
            />
          </div>
        </div>

        <div className="card-overlay bg-gradient p-2">
          <Banner />
        </div>

        <div className="page-content">
          <div className="content">
            <div className="container">
              <div className="row">
                {branchLogo &&
                  branchLogo?.map((data, index) => {
                    return (
                      <>
                        <div className="col-md-6 col-sm-6 col-6">
                          <img
                            className="img-fluid banner-mobile"
                            src={`${BassURl}${data?.logo1}`}
                            width={800}
                          />
                        </div>
                        <div className="col-md-6 col-sm-6 col-6">
                          <img
                            className="img-fluid banner-mobile"
                            width={100}
                            src={`${BassURl}${data?.logo2}`}
                            style={{ marginLeft: "30px" }}
                          />
                        </div>
                      </>
                    );
                  })}
              </div>
              <b className="hr anim mt-2" />
            </div>

            <div className="row mt-3 mb-5">
              {classes &&
                classes?.map((data) => {
                  return (
                    <>
                      <a href="/mobile-select-subject" className="col-6 p-1">
                        <div
                          className="class-item mt-2"
                          style={{ paddingRight: "12px" }}
                        >
                          <div
                            className="image class-select"
                            style={{ padding: "5px" }}
                          >
                            <a
                              href={`/branch/${params.branch_url}/${params.url}/${data?.class_id}/mobile-select-subject`}
                            >
                              <img
                                src={`https://project.digitalnawab.com/kidsschool/${data?.class_image}`}
                                alt="class image"
                                className="img-responsive"
                              />
                            </a>
                          </div>
                          <ul
                            className="schedule "
                            style={{
                              backgroundColor: `${data?.class_color} !important `,
                              marginTop: "-15px",
                            }}
                          >
                            <li className="class-style">
                              <a
                                href={`/branch/${params.branch_url}/${params.url}/${data?.class_id}/mobile-select-subject`}
                                style={{ color: "white" }}
                                className="mb-2"
                              >
                                {" "}
                                <span style={{ lineHeight: "12px" }}>
                                  {data.class}
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </a>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MobileSelectclass;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { BassURl } from "../../Api/Api";
import "./mobilesection.css";

function MobileSelectSubject() {
  const param = useParams();
  const class_ids = localStorage.setItem("class_id", JSON.stringify(param));
  const [subject, setSubject] = useState([]);
  const params = JSON.parse(localStorage.getItem("params"));
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  //  function call class api
  useEffect(() => {
    axios
      .get(
        `${BassURl}api/all-subject?school_id=${currentUser?.school_id}&class_id=${param.class_id}`
      )
      .then((res) => {
        setSubject(res?.data?.data);
        // {res.data.code == 200 ? setClass(res?.data?.data): ('erro')}
        // console.log(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log(subject);
  // getAllBookBySchool
  const [subjectId, setSubjectId] = useState("");

  localStorage.setItem("subjectId", JSON.stringify(subjectId));
  const BookInfo = JSON.parse(localStorage.getItem("currentUser"));
  const class_id = JSON.parse(localStorage.getItem("class_id"));
  const branchData = JSON.parse(localStorage.getItem("branchData"));
  function logout() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("BookId");
    localStorage.removeItem("subjectId");
    localStorage.removeItem("class_id");
    setTimeout(
      (window.location.href = `/branch/${params.branch_url}/${params.url}`),
      1
    );
  }

  const branchLogo = JSON.parse(localStorage.getItem("branchlogo"));

  return (
    <div id="page">
      <div id="footer-bar" className="footer-bar-1">
        {currentUser ? (
          <>
            {" "}
            <a
              href={`/branch/${params.branch_url}/${params.url}/mobile-select-class`}
              className="active-nav"
            >
              <i class="fa fa-home"></i>
              <span>Home</span>
            </a>
          </>
        ) : (
          <>
            {" "}
            <a
              href={`/branch/${params.branch_url}/${params.url}`}
              className="active-nav"
            >
              <i class="fa fa-home"></i>
              <span>Home</span>
            </a>
          </>
        )}

        {currentUser ? (
          <>
            {" "}
            <a onClick={logout}>
              <i className="fa fa-sign-out"></i>
              <span>Log Out</span>
            </a>
          </>
        ) : (
          <>
            {" "}
            <a href={`/branch/${params.branch_url}/${params.url}/all-login`}>
              <i className="fa fa-user"></i>
              <span>Login</span>
            </a>
          </>
        )}

        <a href="/">
          <i className="fa fa-cog"></i>
          <span>Menu</span>
        </a>
      </div>
      <div className="page-content header-clear-small">
        <div className="logo-row-mobile">
          <img
            src={`${BassURl}${branchData?.logo}`}
            alt="logo"
            className="logo-mobile"
          />
        </div>
      </div>
      <div className="card-overlay bg-gradient p-2">
        <img className="img-fluid banner-mobile" src="/images/kidsmobile.png" />
      </div>
      <div className="page-content">
        <div className="contents" style={{ margin: "10px 10px" }}>
          <div className="container">
            <div className="row">
              {branchLogo &&
                branchLogo?.map((data, index) => {
                  return (
                    <>
                      <div className="col-md-6 col-sm-6 col-6">
                        <img
                          className="img-fluid banner-mobile"
                          src={`${BassURl}${data?.logo1}`}
                          width={800}
                        />
                      </div>
                      <div className="col-md-6 col-sm-6 col-6">
                        <img
                          className="img-fluid banner-mobile"
                          width={100}
                          src={`${BassURl}${data?.logo2}`}
                          style={{ marginLeft: "30px" }}
                        />
                      </div>
                    </>
                  );
                })}
            </div>
            <b className="hr anim mt-2" />
          </div>

          <div className="row mt-4">
            {subject &&
              subject?.map((data, index) => {
                return (
                  <>
                    <a
                      href={`/branch/${params.branch_url}/${params.url}/${class_id?.class_id}/subject/${data?.subject_url}`}
                      className="col-6 p-0"
                    >
                      <div
                        className="card card-style me-2 mb-3 "
                        data-card-height={100}
                        style={{ height: "200px" }}
                        onClick={(e) => setSubjectId(data.subject_id)}
                      >
                        <div className="card-top" />

                        <img
                          className="color-whites  py-2 px-3"
                          src={`https://project.digitalnawab.com/kidsschool/${data?.image}`}
                          alt
                          style={{
                            height: "225px",
                            borderTopLeftRadius: "19px",
                            borderTopRightRadius: "19px",
                          }}
                        />
                      </div>
                    </a>
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileSelectSubject;

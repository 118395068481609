import React from 'react';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css'
import { BassURl } from '../Api/Api';
// export default VideoPlayer => {
//     return (
//         <Player
//             playsInline
//             poster="images/poster.webp"
//             src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
//         />
//     );
// };


function VideoPlayer({ data }) {
    return (
        <Player
            playsInline
            poster="/images/study.webp"
            src={`${BassURl}${data?.upload}`}
            
        //    height={300}
        // src='https://www.youtube.com/watch?v=g6fnFALEseI&feature=share&si=ELPmzJkDCLju2KnD5oyZMQ'

        />
    )
}

export default VideoPlayer
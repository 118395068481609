import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { BassURl } from '../../Api/Api';
import authbook from './authbook.jpg'

function MobileSubjectType() {
    const paras = useParams();
    const BookInfo = JSON.parse(localStorage.getItem('currentUser'));
    const class_id = JSON.parse(localStorage.getItem('class_id'));
    const SubjectId = JSON.parse(localStorage.getItem('subjectId'));
    const params = JSON.parse(localStorage.getItem('params'));
    let getAllBookBySchool = {
        school_id: BookInfo.school_id,
        class_id: BookInfo.class,
        subject_id: SubjectId
    }

    // console.log(paras)
    // console.log(getAllBookBySchool)
    const [subject, setSubject] = useState([]);
    useEffect(() => {
        axios.get(`${BassURl}api/all-school-books?school_id=${BookInfo.school_id}&class_id=${class_id?.class_id}&subject_id=${SubjectId}`, getAllBookBySchool).then((res => {
            setSubject(res.data.data)
            console.log(res.data.data)
        })).catch((err => {
            console.log(err)
        }))
    }, []);

    const [allsubject, setallSubject] = useState([]);
    //  function call class api  
    useEffect(() => {


        axios.get(`${BassURl}api/all-subject`).then((res) => {
            setallSubject(res?.data?.data)

            // {res.data.code == 200 ? setClass(res?.data?.data): ('erro')}
            // console.log(res?.data?.data);
        }).catch((err) => {
            console.log(err)

        })
    }, []);
    const [BookId, setBookId] = useState('');
    const branchdata = JSON.parse(localStorage.getItem('branchData'));

    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const branchData = JSON.parse(localStorage.getItem('branchData'));
    function logout() {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('BookId');
        localStorage.removeItem('subjectId');
        localStorage.removeItem('class_id');
        setTimeout(window.location.href = `/branch/${params.branch_url}/${params.url}`, 1)


    }
    localStorage?.setItem('BookId', JSON.stringify(BookId));

    return (<>
        <div id="page">
            <div id="footer-bar" className="footer-bar-1">
                {currentUser ? (<> <a href={`/branch/${params.branch_url}/${params.url}/mobile-select-class`} className="active-nav"><i class="fa fa-home"></i><span>Home</span></a></>) : (<>  <a href={`/branch/${params.branch_url}/${params.url}`} className="active-nav"><i class="fa fa-home"></i><span>Home</span></a></>)}


                {
                    currentUser ? (<> <a onClick={logout}><i className="fa fa-sign-out"></i><span>Log Out</span></a></>) : (<> <a href={`/branch/${params.branch_url}/${params.url}/all-login`}><i className="fa fa-user"></i><span>Login</span></a></>)


                }

                <a href="/"><i className="fa fa-cog"></i><span>Menu</span></a>
            </div>
            <div className="page-content header-clear-small">
                <div className="logo-row-mobile">
                    <img src={`${BassURl}${branchdata?.logo}`} alt="logo" className="logo-mobile" />
                </div>
            </div>
            <div className="card-overlay bg-gradient p-2" >
                <img className="img-fluid banner-mobile" src="/images/kidsmobile.png" />
            </div>

            <div className='page-content'>
                <div className='content' style={{marginBottom:"100px"}}>

                    <div className="row mt-4">
                        {subject && subject?.map((allsubject) => {
                            return (<>
                                <a href={`/branch/${params.branch_url}/${params.url}/${class_id?.class_id}/${allsubject?.book_url}/mobile-chapterwise`} className="col-12 p-1" onClick={(e) => setBookId(allsubject.book_id)}    ><div className="card card-style me-2 mb-3 " data-card-height={100} style={{ height: '290px' }}>

                                    <div className="card-top" />


                                    <img className="color-whites  py-2 px-3" src={`https://project.digitalnawab.com/kidsschool/${allsubject?.book_pic}`} onClick={(e) => setBookId(allsubject.book_id)} alt style={{ height: "250px", width: "250px", margin: "0 auto", borderTopLeftRadius: "19px", borderTopRightRadius: "19px" }} />

                                </div></a>
                                <div style={{marginTop:"-50px"}}>
                                    <ul className="schedule" style={{ filter: 'drop-shadow(black 2px 4px 6px)', lineHeight: "20px" }}><li className="class-style"><a href={`/branch/${params.branch_url}/${params.url}/${class_id?.class_id}/${allsubject?.book_url}/mobile-chapterwise`} style={{ color: 'white' , lineHeight:"12px" }} onClick={(e) => setBookId(allsubject.book_id)}><span>Open Book</span></a></li></ul>
                                </div>


                            </>)
                        })

                        }


                    </div>
                </div>
            </div>
        </div>

    </>)

}
export default MobileSubjectType



import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BassURl } from "../Api/Api";
import SelectNavbar from "../Navbar/SelectNavbar";
import Footer from "./Footer";
import acve from "./acev.png";
import tree from "./tree.webp";

function SelectSubject() {
  const param = useParams();
  const class_id = localStorage.setItem("class_id", JSON.stringify(param));
  const [subject, setSubject] = useState([]);
  const params = JSON.parse(localStorage.getItem("params"));
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  //  function call class api
  useEffect(() => {
    axios
      .get(
        `${BassURl}api/all-subject?school_id=${currentUser?.school_id}&class_id=${param.class_id}`
      )
      .then((res) => {
        setSubject(res?.data?.data);
        // {res.data.code == 200 ? setClass(res?.data?.data): ('erro')}
        // console.log(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // getAllBookBySchool
  const [subjectId, setSubjectId] = useState("");

  localStorage.setItem("subjectId", JSON.stringify(subjectId));
  const BookInfo = JSON.parse(localStorage.getItem("currentUser"));

  return (
    <>
      <SelectNavbar />
      <section className="page-header section-notch">
        <div className="overlay p-5">
          <div className="container mobileMarginTop">
            <h3 style={{ color: "white" }}>Select Subject</h3>
            <ul>
              <li style={{ fontSize: "35px" }}>
                {subject?.slice(0, 1).map((data, index) => {
                  return (
                    <>
                      <strong> {data?.class_title}</strong>
                    </>
                  );
                })}
              </li>
              <li> &nbsp; </li>
              {/* <li style={{fontSize:"25px"}}>Select Subject </li> */}
            </ul>
          </div>
          {/* container */}
        </div>
        {/* overlay */}
      </section>
      <section className="classes padding-120">
        <div className="container  ">
          {/* <div className='row bg-white'>
                        <div className='col-lg-6'>
                            <img src={acve} alt="" style={{ width: "50%" }} srcset="" />
                        </div>
                        <div className='col-lg-6'>

                            <img src={tree} style={{ width: "40%", marginLeft: "300px" }} alt="" />




                        </div>
                        <b className="hr anim mb-5" />
                    </div> */}
          <div className="row">
            {subject &&
              subject?.map((data, index) => {
                console.log(data);
                return (
                  <>
                    <div className="col-lg-3 col-sm-12 col-xs-12  multiClassDiv">
                      <div className="class-item ">
                        <div className="image class-select">
                          <div className="subjectCard">
                            {/* <h3 className='text-center'>{allsubject?.title}</h3> */}

                            <a
                              href={`/branch/${params.branch_url}/${params.url}/subject/${data?.subject_url}`}
                              onClick={(e) => setSubjectId(data.subject_id)}
                            >
                              <img
                                src={`https://project.digitalnawab.com/kidsschool/${data?.image}`}
                                alt="class image"
                                className="img-responsive"
                              />
                            </a>
                          </div>
                        </div>
                        <ul
                          className="schedule"
                          style={{ filter: "drop-shadow(2px 4px 6px black)" }}
                        >
                          <li
                            className="class-style"
                            style={{
                              backgroundColor: `${data?.background_color}`,
                            }}
                          >
                            <a
                              href={`/branch/${params.branch_url}/${params.url}/subject/${data?.subject_url}`}
                              onClick={(e) => setSubjectId(data.subject_id)}
                              style={{ color: "white" }}
                            >
                              <span>Click Here</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      {/* class item */}
                    </div>
                  </>
                );
              })}
          </div>
          {/* row */}
        </div>
        {/* container */}
      </section>
    </>
  );
}

export default SelectSubject;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BassURl } from "../Api/Api";
import SelectNavbar from "../Navbar/SelectNavbar";
import acve from "./acev.png";
import tree from "./tree.webp";
import student from "./student.png";

function SubjectType() {
  const paras = useParams();
  console.log(paras);
  const BookInfo = JSON.parse(localStorage.getItem("currentUser"));
  const class_id = JSON.parse(localStorage.getItem("class_id"));
  const SubjectId = JSON.parse(localStorage.getItem("subjectId"));
  const params = JSON.parse(localStorage.getItem("params"));

  let getAllBookBySchool = {
    school_id: BookInfo.school_id,
    class_id: BookInfo.class,
    subject_id: SubjectId,
  };

  console.log(paras);
  // console.log(getAllBookBySchool)
  const [subject, setSubject] = useState([]);
  useEffect(() => {
    axios
      .get(
        `${BassURl}api/all-school-books?school_id=${BookInfo.school_id}&class_id=${class_id?.class_id}&subject_id=${SubjectId}`,
        getAllBookBySchool
      )
      .then((res) => {
        setSubject(res.data.data);
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [allsubject, setallSubject] = useState([]);
  //  function call class api
  useEffect(() => {
    axios
      .get(`${BassURl}api/all-subject`)
      .then((res) => {
        setallSubject(res?.data?.data);

        // {res.data.code == 200 ? setClass(res?.data?.data): ('erro')}
        // console.log(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [BookId, setBookId] = useState("");
  localStorage.setItem("BookId", JSON.stringify(BookId));
  window.localStorage.setItem("SubjectData", JSON.stringify(subject));

  return (
    <>
      <SelectNavbar />
      <section className="page-header section-notch ">
        <div className="overlay p-5">
          <div className="container mobileMarginTop">
            <h3>{} </h3>
            <ul>
              <li style={{ fontSize: "35px", textTransform: "capitalize" }}>
                <strong>{paras?.subject_url}</strong>{" "}
              </li>
              <li style={{ fontSize: "35px" }}>
                {" "}
                <strong> &nbsp; - &nbsp;</strong>{" "}
              </li>
              <li style={{ fontSize: "35px", textTransform: "capitalize" }}>
                {subject?.slice(0, 1)?.map((data, index) => {
                  return (
                    <>
                      <strong>{data?.class_title} </strong>
                    </>
                  );
                })}
              </li>
            </ul>
          </div>
          {/* container */}
        </div>
        {/* overlay */}
      </section>

      <section className="classes ">
        <div className="container ">
          <div className="row mt-5">
            {subject &&
              subject?.map((allsubject, index) => {
                const style = {
                  background: `url(https://project.digitalnawab.com/kidsschool/${allsubject?.book_pic})`,
                };
                console.log(style);
                return (
                  <>
                    <div className="col-lg-4 col-sm-12 col-xs-12  multiClassDiv">
                      <div className="class-item ">
                        <div className="image class-select">
                          <div className="subjectCard">
                            {/* <h3 className='text-center'>{allsubject?.title}</h3> */}
                            <a
                              href={`/branch/${params.branch_url}/${params.url}/${class_id?.class_id}/${allsubject?.book_url}/chapterwise`}
                              onClick={(e) => setBookId(allsubject.book_id)}
                            >
                              <img
                                src={`https://project.digitalnawab.com/kidsschool/${allsubject?.book_pic}`}
                                alt="class image"
                                className="img-responsive"
                                style={{ height: "400px" }}
                              />
                            </a>
                          </div>
                        </div>
                        <ul
                          className="schedule"
                          style={{ filter: "drop-shadow(2px 4px 6px black)" }}
                        >
                          <li
                            className="class-style"
                            style={{ height: "64px", marginTop: "100px" }}
                          >
                            <a
                              href={`/branch/${params.branch_url}/${params.url}/${class_id?.class_id}/${allsubject?.book_url}/chapterwise`}
                              style={{ color: "white" }}
                              onClick={(e) => setBookId(allsubject.book_id)}
                            >
                              <span style={{ lineHeight: "40px" }}>
                                {allsubject?.title}
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      {/* class item */}
                    </div>
                    <div
                      className="col-lg-8 col-sm-12 col-xs-12  bg-white multiClassDiv"
                      style={{
                        filter: " drop-shadow(2px 4px 6px black)",
                        height: "460px",
                        borderRadius: "12px",
                        border: " 4px solid orange",
                        backgroundImage: `url(${student})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "120%",
                        backgroundPosition: "top",
                      }}
                    >
                      <div className="row">
                        <div
                          className="col-6"
                          style={{
                            paddingLeft: "181px",
                            paddingTop: "100px",
                          }}
                        >
                          <div className="text-left mt-2">
                            <div className="borders">
                              <div>
                                <h4
                                  style={{
                                    color: "#94c846",
                                    fontFamily: "inherit",
                                  }}
                                >
                                  {" "}
                                  Class :
                                </h4>
                              </div>
                            </div>
                          </div>
                          <div className="text-left mt-3">
                            <div className="borders">
                              <div>
                                <h4
                                  style={{
                                    color: "#94c846",
                                    fontFamily: "inherit",
                                  }}
                                >
                                  {" "}
                                  Book :
                                </h4>
                              </div>
                            </div>
                          </div>

                          <div className="text-left mt-2">
                            <div className="borders">
                              <div>
                                <h4
                                  style={{
                                    color: "#94c846",
                                    fontFamily: "inherit",
                                  }}
                                >
                                  {" "}
                                  Subject :
                                </h4>
                              </div>
                            </div>
                          </div>
                          <div className="text-left mt-2">
                            <div className="borders">
                              <div>
                                <h4
                                  style={{
                                    color: "#94c846",
                                    fontFamily: "inherit",
                                  }}
                                >
                                  {" "}
                                  Author :
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-6" style={{ paddingTop: "90px" }}>
                          <div className="text-left mt-3">
                            <div className="borders">
                              <div>
                                <h4
                                  style={{
                                    color: "#271818d9",
                                    fontFamily: "inherit",
                                  }}
                                >
                                  {allsubject?.class_title}
                                </h4>
                              </div>
                            </div>
                          </div>

                          <div className="text-left mt-3">
                            <div className="borders">
                              <div>
                                <h4
                                  style={{
                                    color: "#271818d9",
                                    fontFamily: "inherit",
                                  }}
                                >
                                  {allsubject?.title}
                                </h4>
                              </div>
                            </div>
                          </div>

                          <div className="text-left mt-2">
                            <div className="borders">
                              <div>
                                <h4
                                  style={{
                                    color: "#271818d9",
                                    fontFamily: "inherit",
                                  }}
                                >
                                  {allsubject?.subject_title}
                                </h4>
                              </div>
                            </div>
                          </div>
                          <div className="text-left mt-2">
                            <div className="borders">
                              <div>
                                <h4
                                  style={{
                                    color: "#271818d9",
                                    fontFamily: "inherit",
                                  }}
                                >
                                  {allsubject?.author}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div
                            className=" text-center"
                            style={{ paddingRight: "30px" }}
                          >
                            <button
                              className="btn "
                              style={{
                                border: "2px solid Purple",
                                fontSize: "15px",
                                fontWeight: "800",
                                color: "red",
                              }}
                            >
                              {" "}
                              <a
                                className="blink_me"
                                href={`/branch/${params.branch_url}/${params.url}/${class_id?.class_id}/${allsubject?.book_url}/chapterwise`}
                                onClick={(e) => setBookId(allsubject.book_id)}
                                style={{
                                  padding: "20px",
                                  color: "red",
                                  fontSize: "20px",
                                }}
                              >
                                Open Book
                              </a>{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
          {/* row */}
        </div>
        {/* container */}
      </section>
    </>
  );
}

export default SubjectType;

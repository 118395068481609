import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { BassURl } from "../Api/Api";
import SelectNavbar from "../Navbar/SelectNavbar";
import Footer from "./Footer";
import VideoPlayer from "./VideoPlayer";
import ReactPlayer from "react-player";
import ebook from "./ebook.gif";
import study from "./study.gif";
import { useParams } from "react-router-dom";
import Filtered from "./Filtered";
import acve from "./acev.png";
import tree from "./tree.webp";

function SubjectDetails() {
  const bookId = JSON.parse(localStorage.getItem("BookId"));
  const BookInfo = JSON.parse(localStorage.getItem("currentUser"));
  const class_id = JSON.parse(localStorage.getItem("class_id"));
  const SubjectId = JSON.parse(localStorage.getItem("subjectId"));
  const params = JSON.parse(localStorage.getItem("params"));
  const param = useParams();
  console.log(param);

  const [chapterwise, setchapterwise] = useState([]);
  useEffect(() => {
    axios
      .get(`${BassURl}/api/books-chapter?book_id=${bookId}`)
      .then((res) => {
        setchapterwise(res?.data?.data);
        // {res.data.code == 200 ? setClass(res?.data?.data): ('erro')}
        // console.log(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // filter show data type

  const [mediaType, setmediaType] = useState("");
  // alert(mediaType);

  function filter(e) {
    e.preventDefault();
    // alert(mediaType);
  }

  //   const [filter, setfilter] = useState()

  const filtered = chapterwise?.filter((data) => {
    return data.media_type === mediaType;
  });
  console.log(filtered);
  window.localStorage.setItem("filteredItem", JSON.stringify(filtered));

  function reloading() {
    window.reload();
  }
  const subjectData = JSON.parse(window.localStorage.getItem("SubjectData"));

  const branchLogo = JSON.parse(localStorage.getItem("branchlogo"));

  return (
    <>
      <SelectNavbar />
      <section className="page-header section-notch">
        <div className="overlay p-5">
          <div className="container">
            <h3> </h3>
            <ul>
              <h2 className="blink_me" style={{ color: "white" }}>
                Choose Your Category
              </h2>
            </ul>
          </div>
          {/* container */}
        </div>
        {/* overlay */}
      </section>

      <div></div>
      <section className="classes padding-120">
        <div className="container">
          <div className="row">
            {subjectData?.map((data) => {
              return (
                <>
                  <div className="col-lg-4 col-sm-12 col-xs-12  multiClassDiv">
                    <div className="class-item ">
                      <div className="image class-select">
                        <div className="subjectCard">
                          {/* <h3 className='text-center'>{allsubject?.title}</h3> */}
                          <a>
                            <img
                              src={`https://project.digitalnawab.com/kidsschool/${data?.book_pic}`}
                              alt="class image"
                              className="img-responsive"
                              style={{ height: "400px" }}
                            />
                          </a>
                        </div>
                      </div>
                      <ul
                        className="schedule"
                        style={{ filter: "drop-shadow(2px 4px 6px black)" }}
                      >
                        <li
                          className="class-style"
                          style={{ height: "64px", marginTop: "100px" }}
                        >
                          <a style={{ color: "white" }}>
                            <span style={{ lineHeight: "40px" }}>
                              {data?.title}
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    {/* class item */}
                  </div>
                  <div
                    className="col-lg-8 col-sm-12 col-xs-12  bg-white multiClassDiv"
                    style={{
                      filter: " drop-shadow(2px 4px 6px black)",
                      height: "460px",
                      borderRadius: "12px",
                      border: " 4px solid orange",
                      // backgroundImage: `url(${student})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "104%",
                      position: "sticky",
                    }}
                  >
                    <div
                      className="row mt-3"
                      style={{ top: "50px", zIndex: "1000" }}
                    >
                      {branchLogo &&
                        branchLogo?.map((data, index) => {
                          return (
                            <>
                              <div className="col-lg-6">
                                <img
                                  src={`${BassURl}${data?.logo1}`}
                                  alt=""
                                  style={{ width: "50%" }}
                                  srcset=""
                                />
                              </div>

                              <div className="col-lg-6">
                                <img
                                  src={`${BassURl}${data?.logo2}`}
                                  style={{
                                    width: "40%",
                                    marginLeft: "200px",
                                    marginTop: "-12px",
                                  }}
                                  alt=""
                                />
                              </div>
                            </>
                          );
                        })}

                      <b className="hr anim mb-3" />

                      <div className="col-lg-4 mt-5">
                        <button
                          style={{
                            backgroundColor: "yellow",
                            color: "black",
                            borderRadius: "5px ",
                            width: "500px ",
                            fontSize: "18px",
                          }}
                        >
                          <a
                            href={`/branch/${params.branch_url}/${params.url}/${class_id?.class_id}/${data?.book_url}/chapterwise/videos`}
                            style={{ color: "black" }}
                            onClick={(e) => setmediaType("All Chapters Video")}
                          >
                            {" "}
                            All Chapters Video
                          </a>
                        </button>
                      </div>
                      <div className="col-lg-4 mt-5">
                        <button
                          style={{
                            backgroundColor: "#e84b3a",
                            color: "white",
                            borderRadius: "5px",
                            width: "500px",
                            fontSize: "18px",
                          }}
                          onClick={(e) => setmediaType("Full Video book")}
                        >
                          <a
                            href={`/branch/${params.branch_url}/${params.url}/${class_id?.class_id}/${data?.book_url}/chapterwise/videos`}
                            style={{ color: "white" }}
                          >
                            Full Video
                          </a>
                        </button>
                      </div>
                      {/* <div className="col-lg-4 mt-5">
                      <button style={{ backgroundColor: "rgb(237 41 20)", color: "white", width: "500px", fontSize: "18px" , borderRadius:"5px" }} onClick={(e) => setmediaType("Full Video Book Link")}>Video Link</button>
                    </div> */}
                      {/* <div className="col-lg-4 mt-3">
                      <button style={{ backgroundColor: "#008c99", color: "white", fontSize: "18px ", width: "500px",borderRadius:"5px" }} onClick={(e) => setmediaType("All Chapters Video Link")}>Chapter Videos Link</button>
                    </div> */}
                      {/* <div className="col-lg-4 mt-3">
                      <button className="" style={{ backgroundColor: "rgb(132 186 48)", color: "white", width: "500px ", fontSize: "18px" , borderRadius:"5px" }} onClick={(e) => setmediaType("PDF Flip Book")}>Flip Book </button>

                    </div> */}

                      <div className="col-lg-4 mt-5">
                        <button
                          style={{
                            backgroundColor: "#88c87b",
                            color: "white",
                            width: "500px",
                            fontSize: "18px",
                            borderRadius: "5px",
                          }}
                        >
                          <a
                            href="http://wp-test-closetoutline-com.nt1-p4stl.ezhostingserver.com/class-1-aao-vyakaran-seekhe-trm/"
                            style={{ color: "white" }}
                            onClick={(e) => {
                              setmediaType("PDF Flip Book Link");
                            }}
                          >
                            {" "}
                            Flip Book Link
                          </a>
                        </button>
                      </div>

                      {/* <div className="col-lg-12 text-center mt-3">
                      <button className="" style={{ backgroundColor: "rgb(94 28 209)", color: "white", width: "700px ", fontSize: "18px", borderRadius:"5px" }} onClick={(e) => setmediaType("Download")}>
                        Download
                      </button>
                    </div> */}
                      <div className="col-lg-6 mt-3">
                        <button
                          className=""
                          style={{
                            backgroundColor: "rgb(12 13 252)",
                            color: "white",
                            width: "500px ",
                            fontSize: "18px",
                            borderRadius: "5px",
                          }}
                        >
                          <a
                            href={`/branch/${params.branch_url}/${params.url}/${class_id?.class_id}/${data?.book_url}/chapterwise/videos`}
                            onClick={(e) =>
                              setmediaType("Teacher Resources Manual link")
                            }
                            style={{ color: "white" }}
                          >
                            Teacher Resource Manual
                          </a>
                        </button>
                      </div>

                      <div className="col-lg-6 mt-3">
                        <button
                          style={{
                            backgroundColor: "rgb(12 13 252)",
                            color: "white",
                            width: "500px ",
                            fontSize: "18px",
                            borderRadius: "5px",
                          }}
                          onClick={(e) => setmediaType("Test Generator")}
                        >
                          <a
                            href={`/branch/${params.branch_url}/${params.url}/${class_id?.class_id}/${data?.book_url}/chapterwise/videos`}
                            onClick={(e) => setmediaType("Test Generator")}
                            style={{ color: "white" }}
                          >
                            {" "}
                            Test Generator
                          </a>
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>

        <div className="container-fluid mt-4">
          <div className="row">
            {mediaType === "All Chapters Video" ? (
              <>
                {filtered?.map((data) => {
                  return (
                    <>
                      <div className="col-lg-4 col-sm-4 col-xs-12">
                        <div className="class-item">
                          <div className="image">
                            {data?.media_type == "All Chapters Video" ? (
                              <>
                                <VideoPlayer
                                  width={480}
                                  height={345}
                                  data={data}
                                />
                              </>
                            ) : null}
                          </div>

                          {/* */}
                          <div className="content">
                            <h4>
                              <a>{data?.course_title}</a>
                            </h4>

                            <p>{data?.short_description}</p>
                          </div>
                          <div className="address">
                            <p>
                              <span></span>{" "}
                            </p>
                          </div>
                        </div>
                        {/* class item */}
                      </div>
                    </>
                  );
                })}
              </>
            ) : mediaType === "Full Video book" ? (
              <>
                {filtered?.map((data) => {
                  return (
                    <>
                      {/* {console.log(data, "map function")} */}
                      <div className="col-lg-4 col-sm-4 col-xs-12">
                        <div className="class-item">
                          <div className="image">
                            {data?.media_type == "Full Video book" ? (
                              <>
                                <VideoPlayer
                                  width={480}
                                  height={345}
                                  data={data}
                                />
                              </>
                            ) : null}
                          </div>

                          {/* */}
                          <div className="content">
                            <h4>
                              <a>{data?.course_title}</a>
                            </h4>

                            <p>{data?.short_description}</p>
                          </div>
                          <div className="address">
                            <p>
                              <span></span>{" "}
                            </p>
                          </div>
                        </div>
                        {/* class item */}
                      </div>
                    </>
                  );
                })}
              </>
            ) : mediaType === "Full Video Book Link" ? (
              <>
                {filtered?.map((data) => {
                  return (
                    <>
                      {/* {console.log(data, "map function")} */}
                      <div className="col-lg-4 col-sm-4 col-xs-12">
                        <div className="class-item">
                          <div className="image">
                            {data?.media_type == "Full Video Book Link" ? (
                              <>
                                <ReactPlayer
                                  width={430}
                                  height={300}
                                  url={`${BassURl}${data?.media_url}`}
                                />
                              </>
                            ) : null}
                          </div>

                          {/* */}
                          <div className="content">
                            <h4>
                              <a>{data?.course_title}</a>
                            </h4>

                            <p>{data?.short_description}</p>
                          </div>
                          <div className="address">
                            <p>
                              <span></span>{" "}
                            </p>
                          </div>
                        </div>
                        {/* class item */}
                      </div>
                    </>
                  );
                })}
              </>
            ) : mediaType === "All Chapters Video Link" ? (
              <>
                {filtered?.map((data) => {
                  return (
                    <>
                      {/* {console.log(data, "map function")} */}
                      <div className="col-lg-4 col-sm-4 col-xs-12">
                        <div className="class-item">
                          <div className="image">
                            {data?.media_type == "All Chapters Video Link" ? (
                              <>
                                <ReactPlayer
                                  width={430}
                                  height={300}
                                  url={`${BassURl}${data?.media_url}`}
                                />
                              </>
                            ) : null}
                          </div>

                          {/* */}
                          <div className="content">
                            <h4>
                              <a>{data?.course_title}</a>
                            </h4>

                            <p>{data?.short_description}</p>
                          </div>
                          <div className="address">
                            <p>
                              <span></span>{" "}
                            </p>
                          </div>
                        </div>
                        {/* class item */}
                      </div>
                    </>
                  );
                })}
              </>
            ) : mediaType === "PDF Flip Book" ? (
              <>
                {filtered?.map((data) => {
                  return (
                    <>
                      {/* {console.log(data, "map function")} */}
                      <div className="col-lg-4 col-sm-4 col-xs-12">
                        <div className="class-item">
                          <div className="image">
                            {data?.media_type == "PDF Flip Book" ? (
                              <>
                                <img
                                  src={ebook}
                                  alt=""
                                  srcset=""
                                  style={{ height: "256px" }}
                                />
                              </>
                            ) : null}
                          </div>
                          <ul className="schedule">
                            <li style={{ width: "100%" }}>
                              <span>
                                <a
                                  href={`${data?.media_url}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: "white" }}
                                >
                                  {data?.course_title} Flip Book Link
                                </a>
                              </span>
                            </li>
                          </ul>

                          {/* */}
                          <div className="content">
                            <h4>
                              <a>{data?.course_title}</a>
                            </h4>

                            <p>{data?.short_description}</p>
                          </div>
                          <div className="address">
                            <p>
                              <span></span>{" "}
                            </p>
                          </div>
                        </div>
                        {/* class item */}
                      </div>
                    </>
                  );
                })}
              </>
            ) : mediaType === "PDF Flip Book Link" ? (
              <>
                {filtered?.map((data) => {
                  return (
                    <>
                      {/* {console.log(data, "map function")} */}
                      <div className="col-lg-4 col-sm-4 col-xs-12">
                        <div className="class-item">
                          <div className="image">
                            {data?.media_type == "PDF Flip Book Link" ? (
                              <>
                                <img
                                  src={ebook}
                                  alt=""
                                  srcset=""
                                  style={{ height: "256px" }}
                                />
                              </>
                            ) : null}
                          </div>
                          <ul className="schedule">
                            <li style={{ width: "100%" }}>
                              <span>
                                <a
                                  href={`${data?.media_url}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: "white" }}
                                >
                                  {data?.course_title} Flip Book Link
                                </a>
                              </span>
                            </li>
                          </ul>

                          {/* */}
                          <div className="content">
                            <h4>
                              <a>{data?.course_title}</a>
                            </h4>

                            <p>{data?.short_description}</p>
                          </div>
                          <div className="address">
                            <p>
                              <span></span>{" "}
                            </p>
                          </div>
                        </div>
                        {/* class item */}
                      </div>
                    </>
                  );
                })}
              </>
            ) : mediaType === "Download" ? (
              <>
                {filtered?.map((data) => {
                  return (
                    <>
                      {/* {console.log(data, "map function")} */}
                      <div className="col-lg-4 col-sm-4 col-xs-12">
                        <div className="class-item">
                          <div className="image">
                            {data?.media_type == "Download" ? (
                              <>
                                <img
                                  src={ebook}
                                  alt=""
                                  srcset=""
                                  style={{ height: "256px" }}
                                />
                              </>
                            ) : null}
                          </div>
                          <ul className="schedule">
                            <li style={{ width: "100%" }}>
                              <span>
                                <a
                                  // download={}
                                  href={`${BassURl}${data?.upload}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: "white" }}
                                >
                                  {data?.course_title}
                                </a>
                              </span>
                            </li>
                          </ul>

                          {/* */}
                          <div className="content">
                            <h4>
                              <a>{data?.course_title}</a>
                            </h4>

                            <p>{data?.short_description}</p>
                          </div>
                          <div className="address">
                            <p>
                              <span></span>{" "}
                            </p>
                          </div>
                        </div>
                        {/* class item */}
                      </div>
                    </>
                  );
                })}
              </>
            ) : mediaType === "Teacher Resources Manual link" ? (
              <>
                {filtered?.map((data) => {
                  return (
                    <>
                      {/* {console.log(data, "map function")} */}
                      <div className="col-lg-4 col-sm-4 col-xs-12">
                        <div className="class-item">
                          <div className="image">
                            {data?.media_type ==
                            "Teacher Resources Manual link" ? (
                              <>
                                <img
                                  src={ebook}
                                  alt=""
                                  srcset=""
                                  style={{ height: "256px" }}
                                />
                              </>
                            ) : null}
                          </div>
                          <ul className="schedule">
                            <li style={{ width: "100%" }}>
                              <span>
                                <a
                                  // download={}
                                  href={`${BassURl}${data?.media_url}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: "white" }}
                                >
                                  {data?.course_title}
                                </a>
                              </span>
                            </li>
                          </ul>

                          {/* */}
                          <div className="content">
                            <h4>
                              <a>{data?.course_title}</a>
                            </h4>

                            <p>{data?.short_description}</p>
                          </div>
                          <div className="address">
                            <p>
                              <span></span>{" "}
                            </p>
                          </div>
                        </div>
                        {/* class item */}
                      </div>
                    </>
                  );
                })}
              </>
            ) : mediaType === "Test Generator" ? (
              <>
                {filtered?.map((data) => {
                  return (
                    <>
                      {/* {console.log(data, "map function")} */}
                      <div className="col-lg-4 col-sm-4 col-xs-12">
                        <div className="class-item">
                          <div className="image">
                            {data?.media_type == "Test Generator" ? (
                              <>
                                <img
                                  src={ebook}
                                  alt=""
                                  srcset=""
                                  style={{ height: "256px" }}
                                />
                              </>
                            ) : null}
                          </div>
                          <ul className="schedule">
                            <li style={{ width: "100%" }}>
                              <span>
                                <a
                                  // download={}
                                  href={`${data?.media_url}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: "white" }}
                                >
                                  {data?.course_title}
                                </a>
                              </span>
                            </li>
                          </ul>

                          {/* */}
                          <div className="content">
                            <h4>
                              <a>{data?.course_title}</a>
                            </h4>

                            <p>{data?.short_description}</p>
                          </div>
                          <div className="address">
                            <p>
                              <span></span>{" "}
                            </p>
                          </div>
                        </div>
                        {/* class item */}
                      </div>
                    </>
                  );
                })}
              </>
            ) : (
              <>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="page-content">
                      <div className="content"></div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* row */}
        </div>
        {/* container */}
      </section>
    </>
  );
}

export default SubjectDetails;

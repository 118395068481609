import React from "react";
import { useParams } from "react-router-dom";
import "./menu.css";
import { BassURl } from "../../Api/Api";

function Menu() {
  const params = JSON.parse(localStorage.getItem("params"));
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const branchData = JSON.parse(localStorage.getItem("branchData"));
  function logout() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("BookId");
    localStorage.removeItem("subjectId");
    localStorage.removeItem("class_id");
    setTimeout(
      (window.location.href = `/branch/${params.branch_url}/${params.url}`),
      1
    );
  }
  return (
    <>
      <div id="page">
        <div id="loader-wrapper">
          <div id="loader" />
          <div className="loader-section section-left" />
          <div className="loader-section section-right" />
        </div>

        <div id="footer-bar" className="footer-bar-1">
          {currentUser ? (
            <>
              {" "}
              <a
                href={`/branch/${params.branch_url}/${params.url}/mobile-select-class`}
                className="active-nav"
              >
                <i class="fa fa-home"></i>
                <span>Home</span>
              </a>
            </>
          ) : (
            <>
              {" "}
              <a
                href={`/branch/${params.branch_url}/${params.url}`}
                className="active-nav"
              >
                <i class="fa fa-home"></i>
                <span>Home</span>
              </a>
            </>
          )}

          {currentUser ? (
            <>
              {" "}
              <a onClick={logout}>
                <i className="fa fa-sign-out"></i>
                <span>Log Out</span>
              </a>
            </>
          ) : (
            <>
              {" "}
              <a href={`/branch/${params.branch_url}/${params.url}/all-login`}>
                <i className="fa fa-user"></i>
                <span>Login</span>
              </a>
            </>
          )}

          <a href="/">
            <i className="fa fa-cog"></i>
            <span>Menu</span>
          </a>
        </div>
        {currentUser ? (
          (window.location.href = `/branch/${params.branch_url}/${params.url}/mobile-select-class`)
        ) : (
          <>
            <div className="page-content header-clear-small">
              <div className="logo-row-mobile">
                <img
                  src={`${BassURl}${branchData?.logo}`}
                  alt="logo"
                  className="logo-mobile"
                />
              </div>

              <div className="card-overlay bg-gradient p-2">
                <img
                  className="img-fluid banner-mobile"
                  src="/images/kidsmobile.png"
                />
              </div>
            </div>

            <div className="contents p-2" style={{ marginTop: "0px" }}>
              <a
                href={`/branch/${params.branch_url}/${params.url}/all-login`}
                data-menu="menu-login-1"
                className="float-end btn btn-s bg-highlight rounded-xl m-1  shadow-xl text-uppercase font-900 font-11 mt-2"
                style={{
                  width: "100px",
                  color: "white",
                  borderRadius: "35px !important ",
                }}
              >
                Login
              </a>
              <a
                href={`/branch/${params.branch_url}/${params.url}/all-signup`}
                data-menu="menu-login-1"
                className="float-end btn btn-s bg-highlight rounded-xl m-1  shadow-xl text-uppercase font-900 font-11 mt-2"
                style={{
                  width: "100px",
                  color: "white",
                  borderRadius: "35px !important ",
                }}
              >
                Register
              </a>
            </div>

            <div
              className="page-content header-clear-medium mt-5"
              style={{ transform: "translateX(0px)" }}
            >
              <div className="row text-center mb-0">
                <div data-menu="instant-1" className="col-6 pe-0">
                  <div
                    data-card-height={250}
                    className=" card card-style  opacity-80"
                    style={{ height: 250 }}
                  >
                    <a
                      href={`/branch/${params.branch_url}/${params.url}/all-login`}
                      className="card-bottom btn btn-s btn-full text-uppercase font-900 bg-highlight rounded-s me-2  mb-2"
                    >
                      Teacher
                    </a>
                    <div class="card-style bg-red-dark"></div>
                  </div>
                </div>
                <div data-menu="instant-1" className="col-6 pe-0">
                  <div
                    data-card-height={250}
                    className="card card-style  opacity-80"
                    style={{ height: 250 }}
                  >
                    <a
                      href={`/branch/${params.branch_url}/${params.url}/all-login`}
                      className="card-bottom btn btn-s btn-full text-uppercase font-900 bg-highlights rounded-s me-2  mb-2"
                    >
                      Student
                    </a>
                    <div class="card-style bg-red-dark2"></div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Menu;

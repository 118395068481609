import axios from "axios";
import React, { useEffect, useState } from "react";
import { BassURl } from "../../Api/Api";
import VideoPlayer from "../VideoPlayer";
import ReactPlayer from "react-player";
import ebook from "../ebook.gif";
import study from "../study.gif";
import "./menu.css";
import { useRef } from "react";

function MobileSubjectDetails() {
  const bookId = JSON.parse(localStorage.getItem("BookId"));
  const branchdata = JSON.parse(localStorage.getItem("branchData"));

  const [chapterwise, setchapterwise] = useState([]);
  useEffect(() => {
    axios
      .get(`${BassURl}api/books-chapter?book_id=${bookId}`)
      .then((res) => {
        setchapterwise(res?.data?.data);
        // {res.data.code == 200 ? setClass(res?.data?.data): ('erro')}
        // console.log(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const params = JSON.parse(localStorage.getItem("params"));
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const branchData = JSON.parse(localStorage.getItem("branchData"));
  function logout() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("BookId");
    localStorage.removeItem("subjectId");
    localStorage.removeItem("class_id");
    setTimeout(
      (window.location.href = `/branch/${params.branch_url}/${params.url}`),
      1
    );
  }
  const [mediaType, setmediaType] = useState("");

  // function filter(e) {
  //   e.preventDefault();
  //   // alert(mediaType);
  // }

  //   const [filter, setfilter] = useState()

  let filtered = chapterwise?.filter((data) => {
    return data?.media_type == mediaType;
  });
  const titleRef = useRef();
  const handlescroll = () => {
    titleRef.current.scrollIntoView({ behavior: "smooth" });
  };

  console.log(mediaType);
  console.log(chapterwise);
  const subjectData = JSON.parse(window.localStorage.getItem("SubjectData"));

  const branchLogo = JSON.parse(localStorage.getItem("branchlogo"));

  return (
    <>
      <div id="page">
        <div id="footer-bar" className="footer-bar-1">
          {currentUser ? (
            <>
              {" "}
              <a
                href={`/branch/${params.branch_url}/${params.url}/mobile-select-class`}
                className="active-nav"
              >
                <i class="fa fa-home"></i>
                <span>Home</span>
              </a>
            </>
          ) : (
            <>
              {" "}
              <a
                href={`/branch/${params.branch_url}/${params.url}`}
                className="active-nav"
              >
                <i class="fa fa-home"></i>
                <span>Home</span>
              </a>
            </>
          )}

          {currentUser ? (
            <>
              {" "}
              <a onClick={logout}>
                <i className="fa fa-sign-out"></i>
                <span>Log Out</span>
              </a>
            </>
          ) : (
            <>
              {" "}
              <a href={`/branch/${params.branch_url}/${params.url}/all-login`}>
                <i className="fa fa-user"></i>
                <span>Login</span>
              </a>
            </>
          )}

          <a href="/">
            <i className="fa fa-cog"></i>
            <span>Menu</span>
          </a>
        </div>
        <div className="page-content header-clear-small">
          <div className="logo-row-mobile">
            <img
              src={`${BassURl}${branchdata?.logo}`}
              alt="logo"
              className="logo-mobile"
            />
          </div>
        </div>
        <div className="card-overlay bg-gradient">
          <div className="container">
            <div className="row">
              {branchLogo &&
                branchLogo?.map((data, index) => {
                  return (
                    <>
                      <div className="col-md-6 col-sm-6 col-6">
                        <img
                          className="img-fluid banner-mobile"
                          src={`${BassURl}${data?.logo1}`}
                          width={800}
                        />
                      </div>
                      <div className="col-md-6 col-sm-6 col-6">
                        <img
                          className="img-fluid banner-mobile"
                          width={100}
                          src={`${BassURl}${data?.logo2}`}
                          style={{ marginLeft: "30px" }}
                        />
                      </div>
                    </>
                  );
                })}
              <b className="hr anim" />
            </div>
          </div>
        </div>
        <div className="page-content header-clear-medium mb-3">
          <h4 className="text-center mb-2">choose your category</h4>
          <div
            className="col-lg-8 col-sm-12 col-xs-12 mb-5 bg-white multiClassDiv"
            style={{
              filter: " drop-shadow(2px 4px 6px black)",
              height: "320px",
              borderRadius: "12px",
              border: " 4px solid orange",
              // backgroundImage: `url(${student})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "104%",
              position: "sticky",
              padding: "5px",
            }}
          >
            <div className="row mt-3" style={{ top: "50px", zIndex: "1000" }}>
              <div className="col">
                <button
                  style={{
                    backgroundColor: "yellow",
                    color: "black",
                    width: "500px",
                    borderRadius: "5px",
                    fontSize: "17px",
                  }}
                  onClick={(e) => {
                    setmediaType("All Chapters Video");
                    handlescroll();
                  }}
                >
                  All Chapters Video
                </button>
              </div>
              <div className="col mt-2">
                <button
                  style={{
                    backgroundColor: "#e84b3a",
                    color: "white",
                    width: "500px",
                    fontSize: "17px",
                    borderRadius: "5px",
                  }}
                  onClick={(e) => {
                    setmediaType("Full Video book");
                    handlescroll();
                  }}
                >
                  Full Video
                </button>
              </div>
              {/* <div className="col mt-2">
                <button style={{ backgroundColor: "#fc7f0c", color: "white", width: "500px ", fontSize: "17px", borderRadius: "5px" }} onClick={(e) => { setmediaType("Full Video Book Link"); handlescroll() }}>Video Link</button>
              </div> */}
              {/* <div className="col mt-2">
                <button style={{ backgroundColor: "#008c99", color: "white", fontSize: "18px ", width: "500px", borderRadius: "5px" }} onClick={(e) => { setmediaType("All Chapters Video Link"); handlescroll() }}>Chapter Videos Link</button>
              </div> */}
              {/* <div className="col mt-2">
                <button className="" style={{ backgroundColor: "#92278f", color: "white", width: "500px ", fontSize: "17px", borderRadius: "5px" }} onClick={(e) => { setmediaType("PDF Flip Book"); handlescroll() }}>Flip Book </button>

              </div> */}

              <div className="col mt-2">
                <button
                  style={{
                    backgroundColor: "#88c87b",
                    color: "white",
                    width: "500px ",
                    fontSize: "17px",
                    borderRadius: "5px",
                  }}
                >
                  <a
                    style={{ color: "white" }}
                    onClick={(e) => {
                      setmediaType("PDF Flip Book Link");
                      handlescroll();
                    }}
                  >
                    {" "}
                    Flip Book
                  </a>
                </button>
              </div>

              {/* <div className="col mt-2">
                <button className="" style={{ backgroundColor: "#fc7f0c", color: "white", width: "500px ", fontSize: "17px", borderRadius: "5px" }} onClick={(e) => { setmediaType("Download"); handlescroll() }}>
                  Download

                </button>

              </div> */}

              <div className="col mt-2">
                <button
                  className=""
                  style={{
                    backgroundColor: "rgb(97 141 4)",
                    color: "white",
                    width: "500px  ",
                    fontSize: "17px",
                    borderRadius: "5px",
                  }}
                  onClick={(e) => {
                    setmediaType("Teacher Resources Manual link");
                    handlescroll();
                  }}
                >
                  <a style={{ color: "white" }}> Teacher Resources Manual</a>
                </button>
              </div>

              <div className="col mt-2">
                <button
                  style={{
                    backgroundColor: "rgb(171 9 65)",
                    color: "white",
                    width: "370px",
                    fontSize: "17px",
                    borderRadius: "5px",
                  }}
                  onClick={(e) => {
                    setmediaType("Test Generator");
                    handlescroll();
                  }}
                >
                  Test Generator
                </button>
              </div>
            </div>
          </div>

          {mediaType === "All Chapters Video" ? (
            <>
              {filtered?.map((data) => {
                return (
                  <>
                    <div ref={titleRef} className="col-lg-4 col-sm-4 col-xs-12">
                      <div className="class-item">
                        <div className="image">
                          {data?.media_type == "All Chapters Video" ? (
                            <>
                              <VideoPlayer
                                width={480}
                                height={345}
                                data={data}
                              />
                            </>
                          ) : null}
                        </div>

                        {/* */}
                        <div className="content">
                          <h4>
                            <a>{data?.course_title}</a>
                          </h4>

                          <p>{data?.short_description}</p>
                        </div>
                        <div className="address">
                          <p>
                            <span></span>{" "}
                          </p>
                        </div>
                      </div>
                      {/* class item */}
                    </div>
                    <hr style={{ height: "5px", backgroundColor: "red " }} />
                  </>
                );
              })}
            </>
          ) : mediaType === "Full Video book" ? (
            <>
              {filtered?.map((data) => {
                return (
                  <>
                    {/* {console.log(data, "map function")} */}
                    <div ref={titleRef} className="col-lg-4 col-sm-4 col-xs-12">
                      <div className="class-item">
                        <div className="image">
                          {data?.media_type == "Full Video book" ? (
                            <>
                              <VideoPlayer
                                width={480}
                                height={345}
                                data={data}
                              />
                            </>
                          ) : null}
                        </div>

                        {/* */}
                        <div className="content">
                          <h4>
                            <a>{data?.course_title}</a>
                          </h4>

                          <p>{data?.short_description}</p>
                        </div>
                        <div className="address">
                          <p>
                            <span></span>{" "}
                          </p>
                        </div>
                      </div>
                      {/* class item */}
                    </div>
                  </>
                );
              })}
            </>
          ) : mediaType === "Full Video Book Link" ? (
            <>
              {filtered?.map((data) => {
                return (
                  <>
                    {/* {console.log(data, "map function")} */}
                    <div ref={titleRef} className="col-lg-4 col-sm-4 col-xs-12">
                      <div className="class-item">
                        <div className="image">
                          {data?.media_type == "Full Video Book Link" ? (
                            <>
                              <ReactPlayer
                                width={430}
                                height={300}
                                url={`${BassURl}${data?.media_url}`}
                              />
                            </>
                          ) : null}
                        </div>

                        {/* */}
                        <div className="content">
                          <h4>
                            <a>{data?.course_title}</a>
                          </h4>

                          <p>{data?.short_description}</p>
                        </div>
                        <div className="address">
                          <p>
                            <span></span>{" "}
                          </p>
                        </div>
                      </div>
                      {/* class item */}
                    </div>
                  </>
                );
              })}
            </>
          ) : mediaType === "All Chapters Video Link" ? (
            <>
              {filtered?.map((data) => {
                return (
                  <>
                    {/* {console.log(data, "map function")} */}
                    <div ref={titleRef} className="col-lg-4 col-sm-4 col-xs-12">
                      <div className="class-item">
                        <div className="image">
                          {data?.media_type == "All Chapters Video Link" ? (
                            <>
                              <ReactPlayer
                                width={430}
                                height={300}
                                url={`${BassURl}${data?.media_url}`}
                              />
                            </>
                          ) : null}
                        </div>

                        {/* */}
                        <div className="content">
                          <h4>
                            <a>{data?.course_title}</a>
                          </h4>

                          <p>{data?.short_description}</p>
                        </div>
                        <div className="address">
                          <p>
                            <span></span>{" "}
                          </p>
                        </div>
                      </div>
                      {/* class item */}
                    </div>
                  </>
                );
              })}
            </>
          ) : mediaType === "PDF Flip Book" ? (
            <>
              {filtered?.map((data) => {
                return (
                  <>
                    {/* {console.log(data, "map function")} */}
                    <div ref={titleRef} className="col-lg-4 col-sm-4 col-xs-12">
                      <div className="class-item">
                        <div className="image">
                          {data?.media_type == "PDF Flip Book" ? (
                            <>
                              <img
                                src={ebook}
                                alt=""
                                srcset=""
                                style={{ height: "256px" }}
                              />
                            </>
                          ) : null}
                        </div>
                        <ul className="schedule">
                          <li style={{ width: "100%" }}>
                            <span>
                              <a
                                href={`${BassURl}${data?.upload}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "white" }}
                              >
                                {data?.course_title}
                              </a>
                            </span>
                          </li>
                        </ul>

                        {/* */}
                        <div className="content">
                          <h4>
                            <a>{data?.course_title}</a>
                          </h4>

                          <p>{data?.short_description}</p>
                        </div>
                        <div className="address">
                          <p>
                            <span></span>{" "}
                          </p>
                        </div>
                      </div>
                      {/* class item */}
                    </div>
                  </>
                );
              })}
            </>
          ) : mediaType === "PDF Flip Book Link" ? (
            <>
              {filtered?.map((data) => {
                return (
                  <>
                    {/* {console.log(data, "map function")} */}
                    <div className="col-lg-4 col-sm-4 col-xs-12">
                      <div className="class-item">
                        <div className="image">
                          {data?.media_type == "PDF Flip Book Link" ? (
                            <>
                              <img
                                src={ebook}
                                alt=""
                                srcset=""
                                style={{ height: "256px" }}
                              />
                            </>
                          ) : null}
                        </div>
                        <ul className="schedule">
                          <li style={{ width: "100%" }}>
                            <span>
                              <a
                                href={`${data?.media_url}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "white" }}
                              >
                                {data?.course_title}
                              </a>
                            </span>
                          </li>
                        </ul>

                        {/* */}
                        <div className="content">
                          <h4>
                            <a>{data?.course_title}</a>
                          </h4>

                          <p>{data?.short_description}</p>
                        </div>
                        <div className="address">
                          <p>
                            <span></span>{" "}
                          </p>
                        </div>
                      </div>
                      {/* class item */}
                    </div>
                  </>
                );
              })}
            </>
          ) : mediaType === "Download" ? (
            <>
              {filtered?.map((data) => {
                return (
                  <>
                    {/* {console.log(data, "map function")} */}
                    <div className="col-lg-4 col-sm-4 col-xs-12">
                      <div className="class-item">
                        <div className="image">
                          {data?.media_type == "Download" ? (
                            <>
                              <img
                                src={ebook}
                                alt=""
                                srcset=""
                                style={{ height: "256px" }}
                              />
                            </>
                          ) : null}
                        </div>
                        <ul className="schedule">
                          <li style={{ width: "100%" }}>
                            <span>
                              <a
                                // download={}
                                href={`${BassURl}${data?.upload}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "white" }}
                              >
                                {data?.course_title}
                              </a>
                            </span>
                          </li>
                        </ul>

                        {/* */}
                        <div className="content">
                          <h4>
                            <a>{data?.course_title}</a>
                          </h4>

                          <p>{data?.short_description}</p>
                        </div>
                        <div className="address">
                          <p>
                            <span></span>{" "}
                          </p>
                        </div>
                      </div>
                      {/* class item */}
                    </div>
                  </>
                );
              })}
            </>
          ) : mediaType === "Teacher Resources Manual link" ? (
            <>
              {filtered?.map((data) => {
                return (
                  <>
                    {/* {console.log(data, "map function")} */}
                    <div className="col-lg-4 col-sm-4 col-xs-12">
                      <div className="class-item">
                        <div className="image">
                          {data?.media_type ==
                          "Teacher Resources Manual link" ? (
                            <>
                              <img
                                src={ebook}
                                alt=""
                                srcset=""
                                style={{ height: "256px" }}
                              />
                            </>
                          ) : null}
                        </div>
                        <ul className="schedule">
                          <li style={{ width: "100%" }}>
                            <span>
                              <a
                                // download={}
                                href={`${data?.media_url}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "white" }}
                              >
                                {data?.course_title}
                              </a>
                            </span>
                          </li>
                        </ul>

                        {/* */}
                        <div
                          className="content"
                          style={{ textAlign: "center" }}
                        >
                          <h4>
                            <a>{data?.course_title}</a>
                          </h4>

                          <p className="text-center">
                            {data?.short_description}
                          </p>
                        </div>
                        <div className="address">
                          <p>
                            <span></span>{" "}
                          </p>
                        </div>
                      </div>
                      {/* class item */}
                    </div>
                  </>
                );
              })}
            </>
          ) : mediaType === "Test Generator" ? (
            <>
              {filtered?.map((data) => {
                return (
                  <>
                    {/* {console.log(data, "map function")} */}
                    <div className="col-lg-4 col-sm-4 col-xs-12">
                      <div className="class-item">
                        <div className="image">
                          {data?.media_type == "Test Generator" ? (
                            <>
                              <img
                                src={ebook}
                                alt=""
                                srcset=""
                                style={{ height: "256px" }}
                              />
                            </>
                          ) : null}
                        </div>
                        <ul className="schedule">
                          <li style={{ width: "100%" }}>
                            <span>
                              <a
                                // download={}
                                href={`${data?.media_url}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "white" }}
                              >
                                {data?.course_title}
                              </a>
                            </span>
                          </li>
                        </ul>

                        {/* */}
                        <div className="content">
                          <h4>
                            <a>{data?.course_title}</a>
                          </h4>

                          <p>{data?.short_description}</p>
                        </div>
                        <div className="address">
                          <p>
                            <span></span>{" "}
                          </p>
                        </div>
                      </div>
                      {/* class item */}
                    </div>
                  </>
                );
              })}
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default MobileSubjectDetails;

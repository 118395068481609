import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Base64 } from "js-base64";
import { useParams } from "react-router-dom";
import axios from 'axios';
import { BassURl } from '../../Api/Api';

function Banner() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };


    const paramsData = useParams();
    let datas = Base64.decode(paramsData?.url);
    console.log(datas);

    const [slider, setSlider] = useState("");

    useEffect(() => {
        axios.get(`${BassURl}/api/branch-slider?branch_id=${datas}`).then((res) => {
            setSlider(res.data.data);
        });
    }, []);
    return (
        <>

            <Slider {...settings}>
                {slider &&
                    slider?.map((data, index) => {
                        return (
                            <>
                                <div>
                                    <img src={`${BassURl}${data?.slider}`} alt="Image 1" />
                                </div>
                            </>
                        );
                    })}
            </Slider>

        </>
    )
}

export default Banner